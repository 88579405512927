﻿(function (w, d, $) {
    $(document).ready(function () {
        $('.filter-bar .dropdown-menu .icon-list a').on("click", function (e) {
            var $this = $(this);
            //e.stopPropagation();
            e.preventDefault();
            $this.closest("li").addClass("active").siblings("li").removeClass("active");
            $this.closest(".dropdown").find(".dropdown-button").dropdown("toggle").find(".selected-text").text($this.data("dropdown-text"));
        });
    });
})(window, document, jQuery);
